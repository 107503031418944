#app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}

header {
  grid-area: header;
}

header > .app-bar {
  background-color: #343a40;
}

main {
  grid-area: main;
  display: grid;
  grid-template-rows: 1fr auto;
}

footer {
  background: #343a40;
  color: rgb(200, 200, 200);
  width: 100%;
  height: 5.5dvh;
  padding-top: 1dvh;
}

#app > main {
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
}

#main-content-wrapper {
  min-height: 90%;
  height: 90%;
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}

#main-content-wrapper-mobile {
  min-height: 95%;
  height: 95%;
  display: flex;
  width: 100%;
}

.fullWidth {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

.alert-margin {
  margin-bottom: 10px;
  margin-top: 10px;
}
