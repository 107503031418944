/*@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}*/

.tipImageContainer {
  /*animation: fadeInOut 4s infinite;
  -webkit-animation: fadeInOut 4s infinite;
  -moz-animation: fadeInOut 4s infinite;
  -o-animation: fadeInOut 4s infinite;
  -ms-animation: fadeInOut 4s infinite;*/
}

.tipImageContainer > img {
  height: auto;
  width: 100%;
}
