body {
  width: 100dvw;
  height: 100dvh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
